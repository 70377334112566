
import {Component, Watch} from 'vue-property-decorator';
import {NavigationMenuItemInterface} from '@/interfaces/NavigationMenuItem.interface';
import {NavigationMenuItems} from '@/misc/NavigationMenuItems';
import {ROUTE_COMPANIES, ROUTE_COMPANY, ROUTE_HOME, ROUTE_LOGIN} from '@/router/routes';
import {namespace} from 'vuex-class';
import {StoreActions} from '@/store';
import {NavigationMenuCategoryInterface} from "@/interfaces/NavigationMenuCategory.interface";
import {AUTH_STORE_NAME, AuthStoreGetters} from "@/store/auth.store";
import Account from '@/models/Account';
import {AccountType} from "@/enum/AccountType.enum";
import {APPLICATION_STORE_NAME, ApplicationStoreActions, ApplicationStoreGetters} from "@/store/application.store";
import Company from "@/models/Company";
import Restaurateur from "@/models/Restaurateur";
import {required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import {mixins} from "vue-class-component";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import {AdvertisementSize} from "@/enum/AdvertisementSize.enum";
import Address from "@/models/Address";

const AuthStore = namespace(AUTH_STORE_NAME);
const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component({
  components: {
    NavigationDrawerComponent: () => import(
      /* webpackChunkName: "NavigationDrawerComponent" */
      '@/components/Misc/NavigationDrawer.component.vue'
    ),
    RegisterCompanyInformationComponent: () => import(
      /* webpackChunkName: "RegisterCompanyInformationComponent" */
      '@/components/Authentication/RegisterCompanyInformation.component.vue'
    ),
    AdvertisementComponent: () => import(
      /* webpackChunkName: "AdvertisementComponent" */
      '@/components/Advertisement.component.vue'
    ),
    ContactDialogComponent: () => import(
      /* webpackChunkName: "ContactDialogComponent" */
      '@/components/Contact/ContactDialog.component.vue'
    ),
    StepByStepAgentComponent: () => import(
      /* webpackChunkName: "StepByStepAgentComponent" */
      '@/components/Tutorial/StepByStepAgent.component.vue'
    )
  },
  mixins: [validationMixin],
  validations: {
    companyName: {
      required
    }
  }
})
export default class HomeView extends mixins(ErrorMessageHandlerMixin) {
  @AuthStore.Getter(AuthStoreGetters.CURRENT_ACCOUNT)
  public account?: Account;

  @ApplicationStore.Getter(ApplicationStoreGetters.CURRENT_COMPANY)
  private currentCompany!: Company;

  @ApplicationStore.Action(ApplicationStoreActions.GET_CURRENT_COMPANY)
  public getCompanyById!: (id: string) => Promise<Company>;

  @ApplicationStore.Action(ApplicationStoreActions.UPDATE_CURRENT_COMPANY)
  public updateCompanyNameAction!: (payload: {id: string, company: Company}) => Promise<Company>;

  @AuthStore.Getter(AuthStoreGetters.CURRENT_USER)
  private user?: Restaurateur;

  public navigationMenuItems: NavigationMenuCategoryInterface[] = NavigationMenuItems;

  private showSetCompanyNameDialog: boolean = false;

  private isSettingCompanyName = false;

  private showContactDialog = false;

  private openContact() {
    this.showContactDialog = true;
  }

  private closeContact() {
    this.showContactDialog = false;
  }

  /**
   * checks if the contact button is shown. Currently it is only shown in every user view, so every route that has
   * an 'admin' inside will not display the contact button
   * @private
   */
  private get showContactButton() {
    return !this.$route.path.includes('admin');
  }

  /**
   * checks if the advertisement should be shown
   * @private
   */
  private get showAdvertisement() {
    return this.$route.path.includes('company');
  }

  private get isOnBoardingCompleted(): boolean {
    return this.currentCompany?.onboardingCompleted ?? true;
  }

  private companyName = '';

  private fullSizeAdvertisement = AdvertisementSize.MEDIUM_RECTANGLE;

  async created() {
    // gets the company by id when the id of the route is set
    try {
      if (this.$route?.params.id) {
        // this.showSetCompanyNameDialog = true;
        // tries to get the company by the route id, if it fails, uses fallback method
        const company = await this.getCompanyById(this.$route.params.id);
        if(!Address.isAddressComplete(company.address) && !this.$hasRole(AccountType.PB)) {
          this.showSetCompanyNameDialog = true;
        }
      } else {
        await this.onInvalidCompanyIdUsed();
      }
    } catch (_) {
      await this.onInvalidCompanyIdUsed();
    }


    // Redirect to first entry in navbar:
    if (this.$route.path == `/${ROUTE_HOME}`) {
      // checks if user is gn, redirects the user to the company overview view

      if (this.account?.role === AccountType.GN) {
        return this.$router.push({
          name:
          this.navigationMenuItems[1].items[0].route, params: {id: this.$route.params!.id ?? this.user!.company!.id}
        });
      }

      await this.$router.push({
        name: this.navigationMenuItems[0].items[0].route,
        params: {id: this.$route.params.id ?? this.user!.company!.id}
      });

    }
  }

  /**
   * is called when the id of the company isn't valid, this can happen when the user manually modifies the url and sets
   * a different companyId
   * @private
   */
  private async onInvalidCompanyIdUsed(): Promise<void> {
    try {
      // checks if the account or the role is missing and logs out the user
      if (!this.account || !this.account.role) {
        return await this.onLogout();
      }

      // if user is pb, redirects to the companies overview
      if (this.account?.role == AccountType.PB && this.$route.name !== ROUTE_COMPANIES) {
        await this.$router.push({name: ROUTE_COMPANIES});
        return;
      }

      // user is gn, tries to find the id of the users company, replaces the modified id with the one of the users
      // company, if no company was found, user is logged out
      const id = this.user?.company?.id;
      if(id) {
        // if id was able to be fetched from the users company, it adds the id to the url by just pushing again into
        // the view
        await this.$router.replace({name: ROUTE_COMPANY, params: {id: id}});
        const company = await this.getCompanyById(id!);
        if(!Address.isAddressComplete(company.address) && !this.$hasRole(AccountType.PB)) {
          this.showSetCompanyNameDialog = true;
        }
      }

    } catch (_) {
      await this.onLogout();
    }
  }

  /**
   * Route change handler
   */
  @Watch('$route', {immediate: true})
  private onRouteChange() {
    this.$nextTick(() => {
      this.navigationMenuItems.forEach((category: NavigationMenuCategoryInterface) => {
        category.items.forEach((item: NavigationMenuItemInterface) => {
          item.isActive = false;
        });
      });

      // Set currently active menu item to 'active':
      const activeItem = this.navigationMenuItems.flatMap((category: NavigationMenuCategoryInterface) =>
          category.items).find((item: NavigationMenuItemInterface) => this.$route.name?.includes(item.route)
      );

      if (activeItem != undefined) {
        activeItem.isActive = true;
      }
    });
  }

  /**
   *  is called when the user tries to set the login for the company
   * @private
   */
  private async onSettingCompanyName() {
    try {
      // checks if name is set
      this.$v.$touch();
      if(this.$v.$invalid) {
        return;
      }

      // tries to update the company and updates the current company object as well to show the correct name
      // of the current company
      const payload = Company.parseFromObject({name: this.companyName, legalName: this.companyName});
      await this.updateCompanyNameAction({id: this.$route.params.id, company: payload});
      this.showSetCompanyNameDialog = false;
    } catch(_) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }

  /**
   * On Logout handler
   */
  private async onLogout() {
    // Reset all stores:
    await this.$store.dispatch(StoreActions.CLEAR_ALL_STORES);

    // redirects to login view
    if(this.$route.name !== ROUTE_LOGIN) await this.$router.push({name: ROUTE_LOGIN});
  }
}
